<template>
  <app-form-view
    app="asset"
    model="assettype"
    api-url="asset/asset-type/"
    :title="$t('menu.assetType')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.assetTypeName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>

      <v-card>
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>{{ $t('menu.description') }}</v-tab>
          <v-tab v-if="mode !== 'create'">{{ $t('menu.asset') }}</v-tab>
          <v-tab v-if="mode !== 'create'">{{
            $t('menu.requiredDocument')
          }}</v-tab>
          <v-tab v-if="mode !== 'create'">{{ $t('menu.displayFields') }}</v-tab>
          <v-tab v-if="mode !== 'create'">{{ $t('menu.availableFor') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-input
                  name="Description"
                  type="html-editor"
                  :label="$t('fields.description')"
                  :view="view"
                  v-model="formData.description"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create'">
            <app-table
              server-side
              ref="asset_category_table"
              app="asset"
              model="assetcategory"
              :hide-edit="mode !== 'edit'"
              :hide-delete="mode !== 'edit'"
              :headers="assetCategoryHeaders"
              :server-items="assetCategories"
              :server-items-length="assetCategoriesCount"
              :is-selecteable="true"
              v-model="selectedAssetCategories"
              :loading="loading"
              @server="getRelatedAssetCategories"
              @edit="onEditAssetCategory"
              @delete="onDeleteAssetCategory"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Asset Categories</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialogAssetCategory" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <slot name="actions">
                        <v-btn
                          v-show="mode === 'edit'"
                          dark
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Item
                        </v-btn>
                        <v-btn
                          v-show="
                            selectedAssetCategories.length && mode === 'edit'
                          "
                          color="error"
                          @click="
                            onDeleteAssetCategory(selectedAssetCategories)
                          "
                        >
                          {{ $t('btn.delete') }}
                        </v-btn>
                      </slot>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" class="app-input--approve-wrapper">
                              <app-input
                                v-model="editingAssetCategory.asset_category"
                                name="asset-category"
                                type="select-server"
                                :label="$t('fields.assetCategory')"
                                :view="view"
                                :binds="{
                                  apiUrl:
                                    'asset/asset-category/?active=true&state=approved'
                                }"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="app-input--approve-wrapper">
                              <app-input
                                v-model="editingAssetCategory.note"
                                name="note"
                                :label="$t('fields.note')"
                                :view="view"
                              ></app-input>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onCloseAssetCategory"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onSaveAssetCategory"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
            </app-table>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="asset"
                  model="assettyperequireddocument"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="requiredDocHeader"
                  :server-items="requiredDocs"
                  :server-items-length="requiredDocsCount"
                  :loading="loading"
                  :is-selecteable="true"
                  v-model="selectedRequiredDoc"
                  @server="getRequiredDocs"
                  @delete="onDeleteRequiredDoc"
                  @edit="onEditRequiredDoc"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Required Document</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRequiredDoc"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="
                                    selectedRequiredDoc.length &&
                                    mode === 'edit'
                                  "
                                  color="error"
                                  @click="
                                    onDeleteRequiredDoc(selectedRequiredDoc)
                                  "
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="
                                          editingRequiredDoc.document_template
                                        "
                                        name="document-template"
                                        type="select-server"
                                        :label="$t('fields.documentTemplate')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'doc-template/document/?active=true'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRequiredDoc"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRequiredDoc"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="asset"
                  model="assettype"
                  hide-edit
                  hide-delete
                  :headers="displayFieldsHeader"
                  :server-items="displayFields"
                  :server-items-length="displayFieldsCount"
                  :loading="loading"
                  :is-selecteable="false"
                  @server="getDisplayFields"
                  @edit="onEditDisplayFields"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Display Fields</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogDisplayFields"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  <v-icon left> mdi-pencil </v-icon>
                                  Edit
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>

                            <v-card>
                              <app-cascade-list
                                title-parent="Documents"
                                title-child="Fields"
                                v-model="editingDisplayFields"
                                height="300px"
                                :parent-items="requiredDocs"
                                :get-child-items="getChildDisplayFields"
                                :child-item-comparator="
                                  displayFieldItemComparator
                                "
                                :selected-child-items="displayFieldsSelected"
                              >
                                <template v-slot:parent-item="{ item }">
                                  {{ item.document_template.name }}
                                </template>
                                <template v-slot:child-item="{ item }">
                                  {{ item.field.name }}
                                </template>
                              </app-cascade-list>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseDisplayFields"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveDisplayFields"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="asset"
                  model="assettypecustomercompany"
                  hide-edit
                  hide-delete
                  :is-selecteable="false"
                  :headers="customerCompanyHeader"
                  :server-items="customerCompanies"
                  :server-items-length="customerCompaniesCount"
                  :loading="loading"
                  @server="getCustomerCompanies"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Available For</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogCustomerCompany"
                            max-width="700px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  <v-icon left> mdi-pencil </v-icon>
                                  Edit
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <app-dual-list
                                :title-left="$t('label.availableForAuditFirms')"
                                :title-right="
                                  $t('label.notAvailableForAuditFirms')
                                "
                                :source="allCompanies"
                                v-model="selectedCustomerCompany"
                                :start-from-right="true"
                                height="300px"
                              >
                                <template v-slot:item="{ item }">
                                  {{ item.name }}
                                </template>
                              </app-dual-list>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseCustomerCompany"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveCustomerCompany"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput.vue'
import AppTable from '@components/AppTable.vue'
import AppDualList from '@components/AppDualList.vue'
import AppCascadeList from '@components/AppCascadeList.vue'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'asset-type-form',
  components: {
    AppDualList,
    AppTable,
    AppInput,
    AppFormView,
    AppCascadeList
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_name'],
      loading: false,
      tab: null,
      assetCategories: [],
      assetCategoriesCount: null,
      dialogAssetCategory: false,
      selectedAssetCategories: [],
      editingAssetCategory: {},
      requiredDocs: [],
      requiredDocsCount: 0,
      selectedRequiredDoc: [],
      dialogRequiredDoc: false,
      editingRequiredDoc: {},
      customerCompanies: [],
      customerCompaniesCount: 0,
      dialogCustomerCompany: false,
      allCompanies: [],
      selectedCustomerCompany: [],
      displayFields: [],
      displayFieldsCount: 0,
      dialogDisplayFields: false,
      editingDisplayFields: []
    }
  },
  computed: {
    assetTypeId() {
      return this.$route.params.id
    },
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    assetCategoryHeaders() {
      return [
        {
          text: this.$t('fields.assetCategoryName'),
          value: 'name',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    requiredDocHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'document_template.name',
          hideFilter: true
        },
        { text: 'Actions', value: 'actions', sortable: false, hideFilter: true }
      ]
    },
    displayFieldsHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'document_template.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'field.name',
          hideFilter: true
        }
      ]
    },
    customerCompanyHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'name',
          hideFilter: true
        }
      ]
    }
  },
  mounted() {
    this.$api({
      method: 'get',
      url: 'customer-company/customer_company/?active=true',
      hideSuccessAlert: true
    }).then(({ data }) => {
      this.allCompanies = data.results
    })
  },
  methods: {
    prepareData(data) {
      const asset_categories =
        (this.assetCategoriesCount == null
          ? this.formData.asset_categories
          : this.assetCategories) ?? []
      if (!data.id && this.$route.query.asset_categories) {
        asset_categories.push(this.$route.query.asset_categories)
      }
      data.asset_categories = asset_categories.map(
        (value) => value?.id ?? value
      )
      delete data.customer_companies
      return data
    },
    async getRelatedAssetCategories(options = null) {
      if (!this.assetTypeId) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.asset_types = this.assetTypeId

        const { data } = await this.$api({
          method: 'get',
          url: `asset/asset-type/${this.assetTypeId}/asset-category/`,
          params: params,
          hideSuccessAlert: true
        })
        this.assetCategories = data.results
        this.assetCategoriesCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCloseAssetCategory() {
      this.editingAssetCategory = {}
      this.dialogAssetCategory = false
    },
    onEditAssetCategory(item) {
      this.editingAssetCategory = Object.assign(
        {},
        {
          note: item.note,
          asset_category: item,
          id: item.id
        }
      )
      this.dialogAssetCategory = true
    },
    onDeleteAssetCategory(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `asset/asset-type/${this.assetTypeId}/asset-category/`,
          data: {
            pks: items.map((value) => value.id)
          }
        })
          .then(() => {
            this.selectedAssetCategories = []
            this.$refs.asset_category_table.onServer()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async onSaveAssetCategory() {
      this.loading = true
      try {
        const asset_category_id = this.editingAssetCategory.asset_category.id
          ? this.editingAssetCategory.asset_category.id
          : this.editingAssetCategory.id
        const data = {
          asset_category: asset_category_id,
          note: this.editingAssetCategory.note
        }
        if (this.editingAssetCategory.id) {
          await this.$api({
            method: 'put',
            url: `asset/asset-type/${this.assetTypeId}/asset-category/${this.editingAssetCategory.id}/`,
            data: data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `asset/asset-type/${this.assetTypeId}/asset-category/`,
            data: data
          })
        }

        this.loading = false
        this.onCloseAssetCategory()
        this.$refs.asset_category_table.onServer()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRequiredDocs(options = null) {
      if (!this.assetTypeId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.asset_type = this.assetTypeId

        const { data } = await this.$api({
          method: 'get',
          url: `asset/asset-type-required-document/`,
          params: params,
          hideSuccessAlert: true
        })
        this.requiredDocs = data.results
        this.requiredDocsCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRequiredDoc(item) {
      this.editingRequiredDoc = Object.assign({}, item)
      this.dialogRequiredDoc = true
    },
    onDeleteRequiredDoc(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `asset/asset-type-required-document/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRequiredDocs()
        })
      })
      this.loading = false
    },
    onCloseRequiredDoc() {
      this.dialogRequiredDoc = false
      this.$nextTick(() => {
        this.editingRequiredDoc = {}
      })
      this.loading = false
    },
    async onSaveRequiredDoc() {
      if (!this.editingRequiredDoc.document_template) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.asset_type = this.$route.params.id
        data.document_template = this.editingRequiredDoc.document_template.id

        if (this.editingRequiredDoc.id) {
          await this.$api({
            method: 'put',
            url: `asset/asset-type-required-document/${this.editingRequiredDoc.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `asset/asset-type-required-document/`,
            data
          })
        }
        this.editingRequiredDoc = {}
        this.onCloseRequiredDoc()
        await this.getRequiredDocs()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getDisplayFields(options = null) {
      if (!this.assetTypeId) {
        return
      }

      this.loading = true
      await this.getRequiredDocs()
      try {
        let params = defaultTableParams(options)
        params.asset_type = this.assetTypeId

        const { data } = await this.$api({
          method: 'get',
          url: `asset/asset-type/${this.assetTypeId}/display-field/`,
          params: params,
          hideSuccessAlert: true
        })
        this.displayFields = data.results ?? []
        this.displayFieldsCount = data.count ?? 0
        this.editingDisplayFields = await this.getUnboundedDisplayFields()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getUnboundedDisplayFields() {
      const { data } = await this.$api({
        method: 'get',
        url: `asset/asset-type/${this.assetTypeId}/display-field/`,
        params: {
          asset_type: this.assetTypeId
        },
        hideSuccessAlert: true
      })
      return data.results ?? []
    },
    async getChildDisplayFields(parentItem) {
      if (!parentItem?.document_template?.id) {
        return []
      }
      try {
        this.loading = true
        const ret = await this.$api({
          method: 'get',
          url: `doc-template/document/${parentItem.document_template.id}`,
          // params: params,
          hideSuccessAlert: true
        })
        return (ret.data?.detail?.fields ?? []).map((field) => {
          return {
            field,
            document_template: {
              id: parentItem.document_template.id
            }
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    displayFieldItemComparator(a, b) {
      return (
        a.document_template.id === b.document_template.id &&
        a.field.code === b.field.code
      )
    },
    displayFieldsSelected(parentItem, childItems) {
      if (!parentItem?.document_template?.id) {
        return []
      }
      return childItems.filter(
        (item) => item.document_template.id === parentItem.document_template.id
      )
    },
    onEditDisplayFields() {
      // this.editingDisplayFields = Object.assign({}, item)
      this.dialogDisplayFields = true
    },
    onCloseDisplayFields() {
      this.dialogDisplayFields = false
      this.getUnboundedDisplayFields().then((value) => {
        this.editingDisplayFields = value
      })
      this.loading = false
    },
    async onSaveDisplayFields() {
      try {
        this.loading = true
        const data = {
          display_fields: this.editingDisplayFields.map((item) => {
            return {
              document_template: item.document_template.id,
              field: item.field.code
            }
          })
        }

        // data.asset_type = this.$route.params.id
        // data.document_template = this.editingRequiredDoc.document_template.id

        await this.$api({
          method: 'post',
          url: `asset/asset-type/${this.assetTypeId}/display-field/`,
          data
        })
        this.onCloseDisplayFields()
        await this.getDisplayFields()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getCustomerCompanies(options = null) {
      if (!this.assetTypeId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.asset_types = this.assetTypeId

        const { data } = await this.$api({
          method: 'get',
          url: `asset/asset-type/${this.assetTypeId}/customer-company/`,
          params: params,
          hideSuccessAlert: true
        })
        this.customerCompanies = data.results
        this.customerCompaniesCount = data.count ?? 0
        this.selectedCustomerCompany = this.customerCompanies
          .map((value) => {
            return this.allCompanies.find((company) => company.id === value.id)
          })
          .filter((value) => value !== undefined)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCloseCustomerCompany() {
      this.dialogCustomerCompany = false
      this.selectedCustomerCompany = this.customerCompanies
        .map((value) => {
          return this.allCompanies.find((company) => company.id === value.id)
        })
        .filter((value) => value !== undefined)
      this.loading = false
    },
    async onSaveCustomerCompany() {
      try {
        this.loading = true

        await this.$api({
          method: 'post',
          url: `asset/asset-type/${this.assetTypeId}/customer-company/`,
          data: {
            customer_companies: this.selectedCustomerCompany.map(
              (value) => value.id
            )
          }
        })
        this.onCloseCustomerCompany()
        await this.getCustomerCompanies()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
